exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-ave-tsx": () => import("./../../../src/templates/ave.tsx" /* webpackChunkName: "component---src-templates-ave-tsx" */),
  "component---src-templates-avl-tsx": () => import("./../../../src/templates/avl.tsx" /* webpackChunkName: "component---src-templates-avl-tsx" */),
  "component---src-templates-avs-tsx": () => import("./../../../src/templates/avs.tsx" /* webpackChunkName: "component---src-templates-avs-tsx" */),
  "component---src-templates-event-avl-tsx": () => import("./../../../src/templates/event-avl.tsx" /* webpackChunkName: "component---src-templates-event-avl-tsx" */),
  "component---src-templates-event-avs-tsx": () => import("./../../../src/templates/event-avs.tsx" /* webpackChunkName: "component---src-templates-event-avs-tsx" */),
  "component---src-templates-event-lvsa-tsx": () => import("./../../../src/templates/event-lvsa.tsx" /* webpackChunkName: "component---src-templates-event-lvsa-tsx" */),
  "component---src-templates-lvsa-tsx": () => import("./../../../src/templates/lvsa.tsx" /* webpackChunkName: "component---src-templates-lvsa-tsx" */),
  "component---src-templates-news-ave-tsx": () => import("./../../../src/templates/news-ave.tsx" /* webpackChunkName: "component---src-templates-news-ave-tsx" */),
  "component---src-templates-news-avl-tsx": () => import("./../../../src/templates/news-avl.tsx" /* webpackChunkName: "component---src-templates-news-avl-tsx" */),
  "component---src-templates-news-avs-tsx": () => import("./../../../src/templates/news-avs.tsx" /* webpackChunkName: "component---src-templates-news-avs-tsx" */),
  "component---src-templates-news-lvsa-tsx": () => import("./../../../src/templates/news-lvsa.tsx" /* webpackChunkName: "component---src-templates-news-lvsa-tsx" */)
}

