/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/montserrat.css'
import './src/styles/tailwind.css'
import './src/styles/theme.css'
import './src/styles/markercluster.css'
import './src/styles/leaflet.css'

import React from 'react'
import { App } from './src/layouts/App'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <App {...props}>{element}</App>
}
