import { configureStore, Tuple } from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { createReducer } from './reducers'

export function configureAppStore() {
  const sagaMiddleware = createSagaMiddleware()
  const runSaga = sagaMiddleware.run

  // Create the store with saga middleware
  let middlewares: Tuple<any> = new Tuple()
  middlewares = middlewares.concat(sagaMiddleware)

  // Redux logger
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      collapsed: () => true,
    })
    middlewares.concat(new Tuple(logger))
  }

  const enhancer = createInjectorsEnhancer({
    createReducer,
    runSaga,
  })

  const enhancers: Tuple<any> = new Tuple(enhancer)

  return configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
    reducer: createReducer(),
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production',
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancers),
  })
}
