import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { useInjectSaga } from 'redux-injectors'
import { appSaga } from './saga'
import { IInitialState } from './types'

export const initialState: IInitialState = {
  isLoading: false,
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init() {},
  },
})

export const { actions: appActions, reducer } = slice

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: appSaga })
  return { actions: slice.actions }
}
