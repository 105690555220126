import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSlice } from './slice'

export const App = ({ children }) => {
  // Actions.
  const dispatch = useDispatch()
  const { actions } = useAppSlice()

  // Effects.
  useEffect(() => {
    dispatch(actions.init())
  }, [actions, dispatch])

  return children
}
