import { put, takeLatest } from 'redux-saga/effects'
import { appActions } from './index'
import { regionSwitchActions } from '../../../components/HeaderComponent/RegionSwitchComponent/slice'

export function* initSaga() {
  if (typeof window !== 'undefined') {
    const path = window.location.pathname
    if (path === '/ave' || path.indexOf('/ave/') === 0) yield put(regionSwitchActions.setRegion('ave'))
    else if (path === '/avl' || path.indexOf('/avl/') === 0) yield put(regionSwitchActions.setRegion('avl'))
    else if (path === '/avs' || path.indexOf('/avs/') === 0) yield put(regionSwitchActions.setRegion('avs'))
    else yield put(regionSwitchActions.setRegion(null))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* appSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(appActions.init.type, initSaga)
}
