import { createSlice } from '../../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../../utils/redux-injectors'
import { IRegionSwitch } from './types'

export const initialState: IRegionSwitch = {
  region: null,
}

const slice = createSlice({
  name: 'regionSwitch',
  initialState,
  reducers: {
    setRegion(state, action) {
      state.region = action.payload
    },
  },
})

export const { actions: regionSwitchActions, reducer } = slice

export const useRegionSwitchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
